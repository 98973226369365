.faq-overview {
	.faq-category {
		> .faq-title {
			pointer-events: none;

			.faq-icon {
				display: none !important;
			}
		}

		> .faq-items {
			display: block !important;

			.faq-item {
				padding-left: 40px;
			}
		}
	}
}
