// bg
// =========================================================================
section {
	&.bg-light,
	&.bg-dark,
	&.bg-green {
		margin: 0;
		padding: 6vh 0;
	}
}

// lead-section
// =========================================================================
&:not(.home) {
	.lead-section {
		margin-bottom: -20vh;
	}
}

&.contact-page,
&.one-column-page,
&.two-column-page,
&.three-column-page,
&.accommodation-detail,
&.accommodation-book-succes,
&.service-category-detail.bundle-category,
&.bundle-detail {
	.lead-section {
		padding-bottom: 0 !important;
		margin-bottom: 80px !important;
		@media(max-width: 991px) {
			padding-top: 15px !important;
		}
	}

	@media(max-width: 991px) {
		.mini-sab {
			display: none;
		}
	}
}

&.accommodation-search-book {
	.lead-section {
		padding: 5vh 0 15vh !important;
	}
}

&.bundle-category {
	.lead-section {
		padding: 5vh 0 20vh !important;
	}
}


&.home .lead-section {
	.page-block.wysiwyg {
		> h4 {
			margin-bottom: 35px;
		}
	}
}

&.service-category-detail {
	.lead-section {
		.info {
			display: flex;
			flex-flow: row wrap;
			justify-content: center;

			> div {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		.title {
			order: -1;
		}
	}
}

.lead-section {
	margin: 6vh 0;
	@extend .bg-dark;
	padding: 18vh 0 20vh !important;
	@media(max-width: 991px) {
		padding: 100px 0 30vh !important;
	}

	.container-one-column {
		.container-holder {
			margin: 0 auto;
			text-align: center;
		}

		&:first-of-type {
			.container-holder {
				//max-width: 1130px;
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: -40px;
		margin-top: 80px;
		z-index: +1;
		@media(max-width: 991px) {
			text-align: center;
			justify-content: center !important;
			margin-top: 0px;
			margin-bottom: 30px;
		}

		.page-block {
			@media(max-width: 991px) {
				width: 100%;
			}

			+ .page-block {
				@media(min-width: 992px) {
					margin-left: auto;
				}
			}
		}
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}

	+ section {
		.container {
			position: relative;
			z-index: +1;
		}
	}

	h1 {
		font-size: 3.75rem;
		@include media-breakpoint-down(md) {
			font-size: 1.875rem;
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 0;

	// intro/outro
	.intro,
	.outro {
		max-width: 1130px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
		text-align: center;
	}
}


&.home {


	.bg-beige {
		padding-bottom: 7vh;

		.grid-items {
			margin-top: -24vh;
			z-index: 2;
		}
	}
}


.main section:last-of-type {
	padding-bottom: 275px !important
}

&.home .main section:last-of-type {
	padding-bottom: 65px !important
}

@media(max-width: 1499px) {
	&.home .main section:last-of-type {
		padding-bottom: 185px !important
	}
}

@media(max-width: 991px) {
	&.home .main section:last-of-type {
		padding-bottom: 375px !important;
	}
}

@media(max-width: 565px) {
	&.home .main section:last-of-type {
		padding-bottom: 185px !important
	}
}

&.bundle-detail {
	.container {
		max-width: 1475px;
	}

	.booking-section {
		background: $green;

		.heading {
			color: #fff;
			padding-top: 15px;
		}
	}
}


&.accommodation-detail {
	.lead-section {
		padding: 0 0 0 !important;
	}
}


&.faq-overview .lead-section {
	margin-bottom: 0;
	padding-bottom: 0 !important;
}

.mini-sab {
	padding-top: 30vh;
	background: #2A632A !important;
}

.eyecatcher + .mini-sab {
	padding-top: 0;
}

&.landing-page {
	.lead-section {
		padding-bottom: 3vh !important;
		margin-bottom: 0 !important;

		+ .content-section {
			margin-top: 3vh;
		}
	}
}

&.default.accommodation-book-terms-and-conditions {
	.lead-section {
		margin-bottom: 5vh;
		padding: 5vh 0 5vh !important;
	}
}
