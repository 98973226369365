// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;
	@include media-breakpoint-up(xl) {
		padding: 30px;
	}
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}


@media(min-width: 1199px) {
	.collection {
		margin-bottom: 35px;

		&.full-width-blocks {
			.grid-items {
				> .item {
					flex: 0 0 100%;
					max-width: 100%
				}
			}
		}

		&.two-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 50%;
					max-width: 50%
				}
			}
		}

		&.three-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 33.333%;
					max-width: 33.333%;

					.card-image {
						background: #fff;
						padding: 10px 10px 0;
					}
				}
			}
		}

		&.four-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 25%;
					max-width: 25%;
				}
			}
		}

		&.five-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 20%;
					max-width: 20%;
				}
			}
		}

		&.six-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 16.6666666667%;
					max-width: 16.6666666667%;
					padding: 0 9px;
				}
			}
		}

		&.top-indents {
			.grid-items {
				> .item {
					&:nth-of-type(2n + 2) {
						margin-top: 15px;
					}
				}
			}
		}
	}
}

&.default:not(.landing-page) {

	.bg-green {
		&:before {
			content: '';
			position: absolute;
			left: 0;
			height: 38px;
			width: 100%;
			z-index: 2;
			background: url(/images/bg-green-top.svg);
			background-size: 100% !important;
			top: 2px;
			transform: translateY(-100%);
			background-repeat: no-repeat;
			background-position: bottom;
			@media(max-width: 991px) {
				background-size: cover !important;
				width: 100vw;
			}
		}
	}

	.bg-dark {
		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 0;
			height: 40px;
			width: 100%;
			z-index: 1;
			background-size: 100% !important;
			background-repeat: no-repeat;
			@media(max-width: 991px) {
				background-size: cover !important;
				width: 100vw;
			}
		}

		&:before {
			background: url(/images/bg-dark-top.svg);
			top: 2px;
			transform: translateY(-100%);
			background-position: bottom;
			background-repeat: no-repeat;
		}

		&:after {
			background: url(/images/bg-dark-bottom.svg);
			bottom: 2px;
			transform: translateY(100%);
			background-position: top;
			background-repeat: no-repeat;
		}
	}
}

.bg-green {
	background: $green;
	position: relative;
	z-index: +1;

	h1, h2, h3, h4, h5, h6, p {
		color: $white;
	}

	a {
		color: $red;
	}
}

.bg-beige {
	background: $beige;
	position: relative;

	h1, h2, h4, h5, h6 {
		color: $green-darker !important;
	}

	p {
		color: #000;
	}


	&:after {
		content: '';
		position: absolute;
		left: 0;
		height: 40px;
		width: 100%;
		z-index: 2;
		background: url(/images/bg-light-bottom.svg);
		background-size: 100% !important;
		bottom: 2px;
		transform: translateY(100%);
		background-repeat: no-repeat;
		@media(max-width: 991px) {
			background-size: cover !important;
			width: 100vw;
		}
	}
}

.mini-sab {
	height: 0;
}


.bg-dark {
	position: relative;
}

.container-two-columns .container-holder {
	margin-bottom: 0;
}


&.contact-page {
	.form {
		background: $primary;

		label {
			color: #fff;
		}

		a {
			color: #fff;
		}
	}
}

.social-icons {
	a {
		font-size: 3.1rem;
	}

	span {
		display: none;
	}
}

@include media-breakpoint-down(md) {
	h1 {
		font-size: $mobile-h1-font-size;
	}
	h2 {
		font-size: $mobile-h2-font-size;
	}
	h3 {
		font-size: $mobile-h3-font-size;
	}
	h4 {
		font-size: $mobile-h4-font-size;
	}
	h5 {
		font-size: $mobile-h5-font-size;
	}
	h6 {
		font-size: $mobile-h6-font-size
	}
}

&.default {
	@media(max-width: 1200px) {
		font-size: 1rem !important;
	}
}
