// footer
.footer {
	margin-top: -145px;
	@media(max-width: 991px) {
		margin-top: -275px;
	}

	.footer-content {
		margin-top: 145px;
		background: $dark;
		position: relative;
		padding-top: 45px;
		@media(max-width: 991px) {
			margin-top: 275px;
		}

		* {
			color: #fff;
		}

		&:before {
			content: '';
			position: absolute;
			left: 0;
			height: 65px;
			width: 100%;
			z-index: 2;
			background: url(/images/bg-footer-top.svg);
			background-size: 100% !important;
			top: 2px;
			transform: translateY(-100%);
			background-repeat: no-repeat;
			background-position: bottom;
		}

		.column {
			@media(max-width: 991px) {
				flex: 0 0 100% !important;
				max-width: 100% !important;
			}

			&.one {
				flex: 0 0 50%;
				max-width: 50%;
			}

			&.two {
				flex: 0 0 50%;
				max-width: 50%;
				@media(max-width: 991px) {
					order: -1;
				}
			}
		}

		.logo {
			text-align: right;
			margin-top:-25%;
			z-index: 3;

			a {
				opacity: 1 !important;
			}
		}

		.social-icons {
			li {
				&:not(:last-of-type) {
					margin-right: 15px;
				}

				a {
					&:hover {
						opacity: .6;
					}

					font-size: 3.125rem;
				}
			}
		}

		.footer-text {
			.btn {
				&:first-of-type {
					margin-right: 15px;
				}
			}
		}
	}

	.footer-sitelinks {
		background: $green-dark;

		.column {
			* {
				color: #fff;
				font-size: 1.125rem;
			}

			p {
				margin-bottom: 0;
			}

			&.two {
				text-align: right;
			}
		}
	}

	a {
		&:not(.link) {
			&:hover {
				opacity: .6;
			}
		}
	}

	.footer-logos-section {
		height: 120px;
		display: flex;
		align-items: center;

		.contentblock {
			@media(max-width: 991px) {
				overflow: hidden;
				display: flex;
				flex-flow: nowrap;
			}

			.grid-items {
				@media(min-width: 992px) {
					justify-content: flex-start;
				}
				@media(max-width: 991px) {
					flex: 0 0 auto;
					position: relative;
					display: inline-flex;
					flex-flow: row nowrap;
					margin: 0 !important;
				}

				&:first-of-type {
					@media(max-width: 991px) {
						animation: slide 15s linear infinite;
					}
				}

				&:last-of-type {
					@media(max-width: 991px) {
						animation: slide 15s linear infinite;
					}
				}


				.item {
					flex: 0 0 auto;
					max-width: none;
					width: auto;
					margin-bottom: 0;
					@media(max-width: 991px) {
						display: inline-block;
					}

					> .card {
						border: none !important;
					}

					.card-image {
						img.card-img {
							max-height: 50px;
							width: auto;
						}
					}

					.card-body {
						display: none !important;
					}
				}
			}
		}
	}
}

@keyframes slide {
	from {
		left: 0%;
	}
	to {
		left: -100%;
	}
}

@keyframes slide2 {
	from {
		left: 100%;
	}
	to {
		left: 0%;
	}
}
