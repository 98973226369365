// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$green: #8DAD42;
$green-dark: #2A632A;
$green-darker: #283618;
$green-light: #F3FAE1;
$red: #941E33;
$beige: #F3E9CA;

$white: #fff;
$black: #000;

// theme-colors
$primary: $green;
$secondary: $green-dark;

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $primary;
$link-decoration: underline;

// typography
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@100;200;300;400;500;700;800;900&display=swap');

$font-family-primary: 'Figtree', sans-serif;
$font-family-secondary: 'Arima Madurai', cursive;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 1.5rem; // 18px

$h1-font-size: 6.25rem; // 100px
$h2-font-size: 3.75rem; // 60px
$h3-font-size: 3rem; // 48px
$h4-font-size: 1.875rem; // 30px
$h5-font-size: 1.25rem; // 20px
$h6-font-size: 1rem; // 16px

$mobile-h1-font-size: 1.875rem; // 30px
$mobile-h2-font-size: 1.875rem; // 30px
$mobile-h3-font-size: 1.5rem; // 24px
$mobile-h4-font-size: 1.125rem; // 15px
$mobile-h5-font-size: 1.125rem; // 10px
$mobile-h6-font-size: 1.125rem; // 8px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 700;
$headings-color: $green;

// contextual
$light: $green-light;
$dark: $green-dark;
$text-light: $white;
$text-dark: $black;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

// header height
$headerheight: 95px;
$mobileheaderheight: 65px;

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;
*/

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1670px,
	xxl: 1675px
) !default;

.box-shadow {
	box-shadow: 6px 6px 12px #00000080 !important;
}
