&.accommodation-detail {
    .lead-section {
        padding: 4rem 0 0 !important;
        
        @include media-breakpoint-up(md) {
            padding: 7rem 0 0 !important;
        }
        
        @include media-breakpoint-up(lg) {
            padding: 1rem 0 0 !important;
        }

        .info-nav {
            @extend .flex-nowrap;
            @extend .justify-content-between;
            @extend .mb-0;

            .page-block {
                width: auto;
            }

            .btn-back {
                @extend .mb-0
            }
        }
    }

    .main {
        section {
            &:last-of-type {
                padding-bottom: 0 !important;
                margin-bottom: 275px;
                position: relative;
                z-index: 99;

                @include media-breakpoint-only(md) {
                    margin-bottom: 380px;
                }
            }
        }
    }
}

&.accommodation-book {
    .main section:last-of-type {
        padding-bottom: 0 !important;
    }
}