// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large {
	&:before {
		content: '';
		width: 100%;
		height: 250px;
		position: absolute;
		left: 0;
		top: 0;
		background: transparent linear-gradient(180deg, #000000 0%, #00000000 100%) 0% 0% no-repeat padding-box;
		opacity: .8;
		pointer-events: none;
	}

	@media(max-width: 991px) {
		margin-bottom: -5px;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 100vh;
	min-height: 400px;
	max-height: 1080px;
	align-items: flex-start;
	@media(max-width: 991px) {
		align-items: flex-start;
		height: calc(100vh - 245px);
	}

	.owl-caption {
		padding-top: 250px;
		@media(max-width: 991px) {
			padding-top: 50px;
		}

		.owl-title {
			font-size: $h1-font-size;
			text-shadow: 1px 1px 7px #000;
			@include media-breakpoint-down(xl) {
				font-size: $mobile-h1-font-size;
			}
		}

		.owl-description {
			p {
				text-shadow: 1px 1px 7px #000;
			}
		}
	}
}

.eyecatcher.align-left {
	.owl-carousel .item .owl-caption {
		text-align: left;
	}

	.owl-carousel .owl-dots {
		display: none;

		.owl-dot {
			span {
				width: 38px;
				height: 8px;
				border: none;
				background: #9499A0;
			}

			&.active {
				span {
					background: $red;
				}
			}
		}
	}
}

.homepage-slider2 {
	.owl-caption {
		@media(min-width: 992px) {
			max-width: 580px;
		}
	}
}


// eyecatcher
.eyecatcher {
	position: relative;
	overflow: hidden;

	// vimeo-video
	.vimeo-video {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				/* height = 100 * (9 / 16) = 56.25 */
				height: 56.25vw;
			}

			@media (max-aspect-ratio: 16/9) {
				/* width = 100 / (9 / 16) = 177.777777 */
				width: 177.78vh;
			}
		}
	}
}
