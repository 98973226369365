.collection.grid {
	&.berichten-blocks {
		.item {
			margin-bottom: 5px;

			.card-wide {
				background: transparent;
				border: none;

				.card-image {
					display: none;
				}


				.card-body {
					width: 100%;
					flex: 0 0 100%;
					max-width: 100%;
					padding: 15px 30% 15px 15px;
					@media(max-width: 991px) {
						padding: 0;
					}

					* {
						color: #fff;
					}

					.card-title {
						font-size: 1.875rem;
						position: initial;
						text-shadow: none !important;
					}

					.card-description {
						.card-description-content {
							font-size: 1.125rem;
						}
					}

					.card-buttons {
						.card-btn {
							background: transparent;
							border: none;
							color: $red;
							padding: 0;
							box-shadow: none;
							min-width: 1px;
							font-size: 1.125rem;
						}
					}
				}
			}
		}
	}

	&:not(.homepage-icons) {
		.card-body {
			padding: 60px 25px 35px;

			.card-title {
				@media(min-width: 992px) {
					position: absolute;
					color: #fff;
					font-size: 48px;
					text-shadow: 1px 1px 7px #000;
				}
				font-size: 28px;
				top: 20px;
				color: #000;
			}

			.card-subtitle {
				font-size: 18px;
				@media(min-width: 992px) {
					font-size: 30px;
				}
			}
		}
	}

	.card-description {
		.list.list-icons .list-item {
			padding: 0 0 0 40px;
		}
	}
}


&.home {
	.bg-green {
		.container-two-columns {
			.column.two {
				padding-left: 1rem !important;
			}
		}
	}
}

.assortiment-overview {
	.card-image-caption {
		display: inline-block;
		background: #2A632A !important;
		width: auto;
		max-width: 120px;
	}
}

.gallery-link .gallery-label {
	display: inline-block;
	background: #2A632A !important;
	width: auto;
	max-width: 120px;
	padding: 5px 15px;
	color: #fff;
}
