// btn
.btn {
	box-shadow: $shadow;
	font-weight: 700;
	font-family: $font-family-secondary;
	border: 2px solid;
	border-radius: 27px;
	min-height: 50px;
	line-height: 50px;
	min-width: 250px;
	outline: none;
	padding: 0;
	font-size: 1.25rem;
	padding: 0 15px;
	@include media-breakpoint-down(lg) {
		min-width: 110px;
		font-size: 0.875rem;
		min-height: 34px;
		line-height: 34px;
	}
	// btn-primary
	&.btn-primary {
		background: $red;
		color: $white;
		border-color: $red;


		&:hover,
		&:active {
			background: $white;
			color: $red;
			border-color: $red;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
		}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
