// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: 0.5s;
	max-height: $headerheight;
	@include media-breakpoint-down(lg) {
		max-height: $mobileheaderheight;
	}

	&:after {
		content: url(/images/menu.svg);
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100vw;
		transform: translateY(calc(100% - 1px));
		z-index: +1;
		line-height: 0;
		pointer-events: none;
		max-width: 100%;
	}

	&.sticky {
		box-shadow: $shadow;

		.logo {
			transform: scale(.6) translateY(-40%);
		}
	}

	> .container-three-columns {
		> .container-holder {
			> .column {
				background: #fff;
				z-index: 9002;
			}
		}
	}

	[class^="container-"] {
		.container-holder {
			@include media-breakpoint-down(lg) {
				margin-left: 0 !important;
				margin-right: 0 !important;
			}

			> .column {
				max-height: $headerheight;
				@extend .align-items-center;
				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					max-height: $mobileheaderheight;
				}
				@extend .navbar-expand-xl;

				&.logo-col {
					@extend .align-items-start;
					@include media-breakpoint-down(lg) {
						flex: 0 0 50%;
						max-width: 50%;
					}
				}

				&.clickables-col {
					@extend .justify-content-end;
					@include media-breakpoint-down(lg) {
						flex: 0 0 25%;
						max-width: 25%;
						flex-wrap: nowrap;
						display: flex;
					}
					@media(max-width: 767px) {
						padding: 0;
					}
				}

				&.menu-col {
					@include media-breakpoint-down(lg) {
						flex: 0 0 25%;
						max-width: 25%;
					}
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		span {
			color: $red;
		}
	}

	// logo
	.logo {
		margin: 24px auto 10px;
		width: 100%;
		max-width: 380px;
		transition: 300ms;
		z-index: 9000;

		a {
			@extend .d-block;
			transform: translate(-30px, -6px);

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
		}
		@include media-breakpoint-up(xl) {
		}

		&#default_menu {
			position: fixed;
			top: $headerheight;
			background: $green-dark;
			width: 100%;
			max-width: 500px;
			left: 0;
			transform: translateX(-100%);
			transition: transform 250ms;
			height: calc(100vh - #{$headerheight});
			@include media-breakpoint-down(xl) {
				top: $mobileheaderheight;
				padding-top: 55px;
				height: calc(100vh - #{$mobileheaderheight});
			}

			&.collapse.show {
				transform: translateX(0);

				&:after {
					transform: translateX(0);
				}
			}

			.navbar-nav {
				flex-flow: row wrap;

				li {
					flex: 0 0 100%;
					max-width: 100%;
					@include media-breakpoint-down(xl) {
						padding: 0 25px;
					}

					a {
						color: $white;

						&:hover {
							opacity: .6;
						}
					}

					li {
						a {
							color: $green-dark;
						}
					}
				}
			}

			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 100%;
				width: calc(100vw - 500px);
				height: 100vh;
				background: #00000020;
				z-index: 9000;
				transform: translateX(-100%);
				transition: transform 200ms;
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 25px;
		@media(max-width: 1599px) {
			position: fixed;
			top: 80px;
			z-index: 899;
		}
		@include media-breakpoint-down(lg) {
			top: 75px;
		}

		.navbar-nav.language .nav-item .nav-link img {
			width: 34px;
			height: 34px;
		}
	}

	.social-icons {
		@include media-breakpoint-down(lg) {
			display: none;
		}

		ul {
			@media(max-width: 1521px) {
				flex-wrap: nowrap !important;
			}

			li {
				&:not(:first-of-type) {
					margin-left: 15px;
				}


				a {
					font-size: 1.375rem;

					&:hover {
						opacity: .6;
					}

					i {
						color: $red;
					}
				}
			}
		}
	}

	.zoek_en_boek_header {
		@media(min-width: 768px) {
			margin-left: 20px;
		}

		a {
			@extend .btn;
			@extend .btn-primary;
			@include media-breakpoint-down(lg) {
				margin: 0 !important;
				padding: 0;
			}
			@media(max-width: 485px) {
				padding: 0 !important;
			}
		}
	}
}

// home
&.home {
	.header {
		@include media-breakpoint-up(xl) {
			position: fixed;
		}
	}
}

.navbar-expand-xl .navbar-toggler,
.header [class^="container-"] .container-holder > .column .navbar-toggler {
	display: block;
}

.navbar-toggler:not([aria-expanded=false]) {
	i {
		&:before {
			content: '\f00d';
		}
	}
}
