.homepage-icons {
	.item {
		@media(max-width: 767px) {
			flex: 0 0 50% !important;
			max-width: 50% !important;
		}

		.card {
			background: transparent;
			border: none;

			.card-image {
				background: $green;
				height: 174px;
				width: 174px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: auto;
				margin-right: auto;
				border-radius: 50%;
				@media(max-width: 450px) {
					width: 140px;
					height: 140px;
				}
				@media(max-width: 450px) {
					width: 125px;
					height: 125px;
				}

				img {
					transition: 200ms;
				}
			}

			h3.card-title {
				font-size: $font-size-base;
				color: $white;
				@media(max-width: 450px) {
					font-size: 1rem;
				}
			}
		}

		&:hover {
			img {
				transform: scale(1.2);
			}
		}
	}
}
