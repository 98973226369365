// mini-sab
.mini-sab {
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: 15px 0 0 0;
			padding: 15px 0;
			border-radius: 4px;
			background: $primary;
			box-shadow: 3px 3px 6px rgba($black, 0.15);
			color: #fff;
			justify-content: center;

			@include media-breakpoint-up(lg) {
				margin-top: -50px;
				padding-left: 30px;
				padding-right: 30px;
			}

			.heading {
				font-size: 24px !important;
			}
		}
	}

	@media (max-width: 991px) {
		height: auto !important;
		margin-bottom: -15px;
	}

	.container {
		max-width: 1300px;
		@media (max-width: 991px) {
			padding: 0;
		}

		.container-holder {
			background-size: cover;
			box-shadow: none;
			width: 100%;
			height: 235px;
			background: transparent;
			@media (min-width: 992px) {
				background: url(/images/zab-bg.svg);
				background-repeat: no-repeat;
				margin-top: -130px;
			}

			> .column {
				position: absolute;
				width: auto;
				top: 100px;
				transform: translateY(-50%);
				margin-bottom: 20px;
				max-width: 1120px;
				@media (max-width: 991px) {
					background: $green;
					background-size: 100%;
					background-repeat: no-repeat;
					padding-top: 15px;
					border-top: 3px solid $green-dark;
				}
				@media (max-width: 767px) {
					width: 100%;
				}

				&:before,
				&:after {
					@media (max-width: 991px) {
						content: "";
						position: absolute;
						left: 0;
						height: 42px;
						width: 100%;
						z-index: 1;
						background-size: 100% !important;
						background-repeat: no-repeat;
					}
				}

				&:before {
					//@media(max-width: 991px) {
					//	content: '';
					//	background: url(/images/zab-mobile-bg-top.svg);
					//	top: 15px;
					//	transform: translateY(-100%);
					//	background-position: bottom;
					//	background-repeat: no-repeat;
					//	height: 70px;
					//	z-index: -1;
					//}
				}

				&:after {
					@media (max-width: 991px) {
						background: url(/images/zab-mobile-bg-bottom.svg);
						bottom: 2px;
						transform: translateY(100%);
						background-position: top;
						background-repeat: no-repeat;
					}
				}
			}
		}
	}
}

&.home {
	.mini-sab {
		.container {
			.container-holder {
				&:before {
					content: "";
					background: url("/images/beer met honing.png");
					background-size: contain;
					left: 50%;
					top: 40px;
					width: 100%;
					position: absolute;
					height: 400px;
					transform: translate(-50%, -100%);
					background-position: 50% 80%;
					background-repeat: no-repeat;
					pointer-events: none;
					max-width: 500px;

					@media (max-width: 991px) {
						z-index: 100;
						top: 15px;
						max-width: 250px;
						transform: translate(-50%, -100%);
					}
				}
			}
		}
	}

	&.w3-booking-widget-fullscreen-active  {
		.mini-sab {
			.container {
				.container-holder {
					> .column {
						transform: none;
						z-index: 999;
					} 
				}
			}
		}
	}
}
